


























import { Component, Prop, Vue } from "vue-property-decorator";
import { CompanyRecommendation } from "@/types/company";

@Component
export default class RecommendationList extends Vue {
    @Prop({ type: Array as () => CompanyRecommendation[], required: true })
    readonly recommendations!: CompanyRecommendation[];
}
