









import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CompanyLocationItem extends Vue {
    @Prop({ type: String, required: true }) readonly location!: string;
    @Prop({ type: Boolean, required: true }) readonly isHeadquarters!: boolean;
}
