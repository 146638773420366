

































import { Component, Prop, Vue } from "vue-property-decorator";
import { CompanyPartner } from "@/types/company";

@Component
export default class PartnerList extends Vue {
    @Prop({ type: Array as () => CompanyPartner[], required: true })
    readonly partners!: CompanyPartner[];
}
