













































































import { Component, Vue} from 'vue-property-decorator';
import axios, { APIResponse } from '@/plugins/axios';
import AlertModule, { AlertType } from '@/store/modules/alert';

@Component
export default class ContactForm extends Vue {
    private forename = '';
    private name = '';
    private email = '';
    private subject = '';
    private message = '';

    private async sendContactMessage() {
        const contactData = {
            forename: this.forename,
            name: this.name,
            email: this.email,
            subject: this.subject,
            message: this.message
        };

        try {
            const response = await axios.post<APIResponse>('/contactform/add', contactData);
            if (response.data.status === 'error') throw response.data.message;

            AlertModule.showAlert({
                message: this.$t('alerts.success').toString(),
                type: AlertType.SUCCESS
            });

            (this.$refs.form as Vue & { reset: () => void }).reset();
        } catch (err) {
            console.error(err);

            AlertModule.showAlert({
                message: this.$t('alerts.error').toString(),
                type: AlertType.ERROR
            });
        }
    }
}
