











import { Component, Prop, Vue } from 'vue-property-decorator';
import CompanyLocationListItem from './CompanyLocationListItem.vue';
import { CompanyLocation } from '@/types/company';

@Component({
    components: {
        CompanyLocationListItem
    }
})
export default class CompanyLocationList extends Vue {
    @Prop({ type: Array as () => CompanyLocation[], required: true }) readonly companyLocations!: CompanyLocation[];
}
