
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CountCard extends Vue {
    @Prop({ type: Number, required: true}) readonly count!: number;
    @Prop({ type: String, required: true}) readonly text!: string;
    @Prop({ type: String, required: true}) readonly icon!: string;
}
