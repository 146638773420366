














import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ContactCard extends Vue {
    @Prop({ type: String, required: true }) readonly icon!: string;
    @Prop({ type: String, required: true }) readonly title!: string;
    @Prop({ type: String, required: true }) readonly text!: string;
}
